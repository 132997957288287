// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-house-layout-house-layout-js": () => import("./../../../src/layouts/HouseLayout/HouseLayout.js" /* webpackChunkName: "component---src-layouts-house-layout-house-layout-js" */),
  "component---src-pages-1-house-js": () => import("./../../../src/pages/1/house.js" /* webpackChunkName: "component---src-pages-1-house-js" */),
  "component---src-pages-1-index-js": () => import("./../../../src/pages/1/index.js" /* webpackChunkName: "component---src-pages-1-index-js" */),
  "component---src-pages-2-index-js": () => import("./../../../src/pages/2/index.js" /* webpackChunkName: "component---src-pages-2-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

