module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Brochacki","short_name":"Brochacki","start_url":"/","icon":"src/assets/images/favicon/apple-touch-icon-120x120.png","icons":[{"src":"src/assets/images/favicon/apple-touch-icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"src/assets/images/favicon/apple-touch-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"src/assets/images/favicon/apple-touch-icon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"src/assets/images/favicon/apple-touch-icon-114x114.png","sizes":"114x114","type":"image/png"},{"src":"src/assets/images/favicon/apple-touch-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"src/assets/images/favicon/apple-touch-icon-57x57.png","sizes":"57x57","type":"image/png"},{"src":"src/assets/images/favicon/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/assets/images/favicon/favicon-16x16.png","sizes":"16x16","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5a5acb809163853935653821d8a8eb2d"},
    }]
